import { createFeature, createReducer, on } from '@ngrx/store';
import {
  GetMaintenanceCommandResult,
  MaintenanceHistoryCommentResponse,
  MaintenanceHistoryResponse,
  MaintenanceResponse,
} from '@connected/fleet-service/models';
import { MaintenanceActions } from './maintenance.actions';
import {
  OverviewFilterSetup,
  OverviewValue,
} from '@connected/shared/overview-table/util';
import { Sort } from '@angular/material/sort';
import { mapPPI2PPG } from '@connected/shared/methods';

//NOTE: adjust after encapsulation
export const maintenanceKey = 'maintenance_new';
export interface MaintenanceState {
  dashboardMaintenance: GetMaintenanceCommandResult | null;
  isDashboardMaintenanceLoading: boolean;
  hasDashboardMaintenanceFailed: boolean;
  maintenanceOverview: OverviewValue[];
  due_soon: number;
  due: number;
  operational: number;
  isMaintenanceOverviewLoading: boolean;
  hasMaintenanceOverviewFailed: boolean;

  filterSetup: OverviewFilterSetup;
  sort: Sort;

  maintenance: MaintenanceResponse | null;
  equipmentHasActiveContractForMaintenance: boolean;
  isMaintenanceLoading: boolean;
  hasMaintenanceFailed: boolean;
  hasNoContentForMaintenance: boolean;

  maintenanceHistory: MaintenanceHistoryResponse[] | null;
  isMaintenanceHistoryLoading: boolean;
  hasMaintenanceHistoryFailed: boolean;
  sortMaintenanceHistory: Sort;

  maintenanceHistoryComments: MaintenanceHistoryCommentResponse[];
  isCommentsLoading: boolean;
}

export const initialState: MaintenanceState = {
  dashboardMaintenance: null,
  isDashboardMaintenanceLoading: true,
  hasDashboardMaintenanceFailed: false,
  maintenanceOverview: [] as OverviewValue[],
  due_soon: 0,
  due: 0,
  operational: 0,
  isMaintenanceOverviewLoading: true,
  hasMaintenanceOverviewFailed: false,
  filterSetup: {
    text: '',
    carrier: null,
    status: null,
    equipment: null,
    equipment_number: null,
    equipment_type: null,
  },
  sort: { active: 'maintenance_indicator', direction: 'desc' },
  maintenance: {} as MaintenanceResponse,
  equipmentHasActiveContractForMaintenance: true,
  isMaintenanceLoading: true,
  hasMaintenanceFailed: false,
  hasNoContentForMaintenance: false,

  maintenanceHistory: [] as MaintenanceHistoryResponse[],
  isMaintenanceHistoryLoading: true,
  hasMaintenanceHistoryFailed: false,
  sortMaintenanceHistory: { active: 'position', direction: 'desc' },

  maintenanceHistoryComments: [] as MaintenanceHistoryCommentResponse[],
  isCommentsLoading: true,
};

const _maintenanceReducer = createReducer(
  initialState,
  on(MaintenanceActions.LoadDashboardMaintenance, (state) => ({
    ...state,
    dashboardMaintenance: initialState.dashboardMaintenance,
    isDashboardMaintenanceLoading: true,
    hasDashboardMaintenanceFailed: false,
  })),
  on(
    MaintenanceActions.LoadDashboardMaintenanceSuccess,
    (state, { payload }) => ({
      ...state,
      dashboardMaintenance: payload,
      isDashboardMaintenanceLoading: false,
      hasDashboardMaintenanceFailed: false,
    }),
  ),
  on(MaintenanceActions.LoadDashboardMaintenanceError, (state) => ({
    ...state,
    isDashboardMaintenanceLoading: false,
    hasDashboardMaintenanceFailed: true,
  })),
  on(MaintenanceActions.LoadMaintenanceOverview, (state) => ({
    ...state,
    isMaintenanceOverviewLoading:
      state.maintenanceOverview === initialState.maintenanceOverview,
    hasMaintenanceOverviewFailed: false,
  })),
  on(
    MaintenanceActions.LoadMaintenanceOverviewSuccess,
    (state, { payload }) => ({
      ...state,
      maintenanceOverview:
        payload.maintenance
          ?.map(
            (maintenance) =>
              <OverviewValue>{
                carrier: maintenance.carrier,
                equipment_number: maintenance.equipment_number,
                equipment_type_icon: maintenance.equipment_type_icon,
                equipment: maintenance.equipment,
                has_telematics: maintenance.has_telematics,
                operating_hours: maintenance.operating_hours_to_maintenance,
                operating_days: maintenance.operating_days_to_maintenance,
                operating_hours_percentage:
                  maintenance.operating_hours_to_maintenance_percentage,
                operating_days_percentage:
                  maintenance.operating_days_to_maintenance_percentage,
                operating_hours_status:
                  maintenance.operating_hours_to_maintenance_status,
                operating_days_status:
                  maintenance.operating_days_to_maintenance_status,
                expected_date: maintenance.expected_maintenance_date,
              },
          )
          ?.map((item) => mapPPI2PPG(item, 'equipment_type_icon')) || [],
      due_soon: payload.due_soon || 0,
      due: payload.over_due || 0,
      operational: payload.operational || 0,
      isMaintenanceOverviewLoading: false,
      hasMaintenanceOverviewFailed: false,
    }),
  ),
  on(MaintenanceActions.LoadMaintenanceOverviewError, (state) => ({
    ...state,
    isMaintenanceOverviewLoading: false,
    hasMaintenanceOverviewFailed: true,
  })),

  on(MaintenanceActions.ClearMaintenance, (state) => ({
    ...state,
    maintenance: initialState.maintenance,
    isMaintenanceLoading: initialState.isMaintenanceLoading,
    hasMaintenanceFailed: initialState.hasMaintenanceFailed,

    maintenanceHistory: initialState.maintenanceHistory,
    isMaintenanceHistoryLoading: initialState.isMaintenanceHistoryLoading,
    hasMaintenanceHistoryFailed: initialState.hasMaintenanceHistoryFailed,
    sortMaintenanceHistory: initialState.sortMaintenanceHistory,
  })),
  on(MaintenanceActions.LoadMaintenance, (state) => ({
    ...state,
    maintenance: initialState.maintenance,
    isMaintenanceLoading: true,
    hasMaintenanceFailed: false,
  })),
  on(
    MaintenanceActions.LoadMaintenanceSuccess,
    (state, { maintenance, equipmentHasActiveContractForMaintenance }) => ({
      ...state,
      maintenance: maintenance ?? initialState.maintenance,
      equipmentHasActiveContractForMaintenance:
        equipmentHasActiveContractForMaintenance ??
        initialState.equipmentHasActiveContractForMaintenance,
      isMaintenanceLoading: false,
      hasMaintenanceFailed: false,
    }),
  ),
  on(MaintenanceActions.LoadMaintenanceError, (state) => ({
    ...state,
    isMaintenanceLoading: false,
    hasMaintenanceFailed: true,
  })),
  on(MaintenanceActions.LoadMaintenanceErrorNoData, (state) => ({
    ...state,
    isMaintenanceLoading: false,
    hasMaintenanceFailed: true,
    hasNoContentForMaintenance: true,
  })),
  on(MaintenanceActions.SetFilter, (state, { payload }) => ({
    ...state,
    filterSetup: payload,
  })),

  on(MaintenanceActions.LoadMaintenanceHistory, (state) => ({
    ...state,
    maintenanceHistory: initialState.maintenanceHistory,
    isMaintenanceHistoryLoading: true,
    hasMaintenanceHistoryFailed: false,
  })),
  on(
    MaintenanceActions.LoadMaintenanceHistorySuccess,
    (state, { maintenanceHistories }) => ({
      ...state,
      maintenanceHistory:
        maintenanceHistories ?? initialState.maintenanceHistory,
      isMaintenanceHistoryLoading: false,
      hasMaintenanceHistoryFailed: false,
    }),
  ),
  on(MaintenanceActions.LoadMaintenanceHistoryError, (state) => ({
    ...state,
    isMaintenanceHistoryLoading: false,
    hasMaintenanceHistoryFailed: true,
  })),
  on(MaintenanceActions.saveSort, (state, { payload }) => ({
    ...state,
    sort: payload,
  })),
  on(MaintenanceActions.saveSortHistory, (state, { payload }) => ({
    ...state,
    sortMaintenanceHistory: payload,
  })),
  on(MaintenanceActions.LoadMaintenanceComments, (state) => ({
    ...state,
    isCommentsLoading: true,
  })),
  on(
    MaintenanceActions.LoadMaintenanceCommentsSuccess,
    (state, { payload }) => ({
      ...state,
      maintenanceHistoryComments: payload,
      isCommentsLoading: false,
    }),
  ),
  on(MaintenanceActions.LoadMaintenanceCommentsError, (state) => ({
    ...state,
    isCommentsLoading: false,
  })),
);

export const MaintenanceFeature = createFeature({
  name: maintenanceKey,
  reducer: _maintenanceReducer,
});
