import {
  GetMaintenanceCommandResult,
  MaintenanceHistoryCommentResponse,
  MaintenanceHistoryResponse,
  MaintenanceResponse,
} from '@connected/fleet-service/models';
import { createAction, props } from '@ngrx/store';
import { MaintenanceFilterSetup } from '../models';
import { Sort } from '@angular/material/sort';

export namespace MaintenanceActions {
  export const LoadDashboardMaintenance = createAction(
    '[Maintenance] LOAD_DASHBOARD_MAINTENANCE',
    props<{ payload: number | undefined }>(),
  );
  export const LoadDashboardMaintenanceSuccess = createAction(
    '[Maintenance] LOAD_DASHBOARD_MAINTENANCE_SUCCESS',
    props<{ payload: GetMaintenanceCommandResult }>(),
  );
  export const LoadDashboardMaintenanceError = createAction(
    '[Maintenance] LOAD_DASHBOARD_MAINTENANCE_ERROR',
  );

  export const SetFilter = createAction(
    '[Maintenance] SET_MAINTENANCE_FILTER',
    props<{
      payload: MaintenanceFilterSetup;
    }>(),
  );

  export const LoadMaintenanceOverview = createAction(
    '[Maintenance] LOAD_MAINTENANCE_OVERVIEW',
  );
  export const LoadMaintenanceOverviewSuccess = createAction(
    '[Maintenance] LOAD_MAINTENANCE_OVERVIEW_SUCCESS',
    props<{ payload: GetMaintenanceCommandResult }>(),
  );
  export const LoadMaintenanceOverviewError = createAction(
    '[Maintenance] LOAD_MAINTENANCE_OVERVIEW_ERROR',
  );
  export const ClearMaintenance = createAction(
    '[Maintenance] CLEAR_MAINTENANCE',
  );
  export const LoadMaintenance = createAction(
    '[Maintenance] LOAD_MAINTENANCE',
    props<{ payload: string }>(),
  );
  export const RenewMaintenance = createAction(
    '[Maintenance] RENEW_MAINTENANCE',
    props<{ payload: string }>(),
  );
  export const LoadMaintenanceSuccess = createAction(
    '[Maintenance] LOAD_MAINTENANCE_SUCCESS',
    props<{
      maintenance?: MaintenanceResponse | null;
      equipmentHasActiveContractForMaintenance?: boolean;
    }>(),
  );
  export const LoadMaintenanceError = createAction(
    '[Maintenance] LOAD_MAINTENANCE_ERROR',
  );
  export const LoadMaintenanceErrorNoData = createAction(
    '[Maintenance] LOAD_MAINTENANCE_ERROR_NO_DATA',
  );
  export const saveSort = createAction(
    '[Maintenance] SAVE_SORT_MAINTENANCE',
    props<{ payload: Sort }>(),
  );

  export const LoadMaintenanceHistory = createAction(
    '[Maintenance] LOAD_MAINTENANCE_HISTORY',
    props<{ payload: string }>(),
  );
  export const LoadMaintenanceHistorySuccess = createAction(
    '[Maintenance] LOAD_MAINTENANCE_HISTORY_SUCCESS',
    props<{
      maintenanceHistories?: MaintenanceHistoryResponse[] | null;
    }>(),
  );
  export const LoadMaintenanceHistoryError = createAction(
    '[Maintenance] LOAD_MAINTENANCE_HISTORY_ERROR',
  );

  export const saveSortHistory = createAction(
    '[Maintenance] SAVE_SORT_MAINTENANCE_HISTORY',
    props<{ payload: Sort }>(),
  );

  export const LoadMaintenanceComments = createAction(
    '[Maintenance] LOAD_MAINTENANCE_COMMENTS',
    props<{ payload: number }>(),
  );

  export const LoadMaintenanceCommentsSuccess = createAction(
    '[Maintenance] LOAD_MAINTENANCE_COMMENTS_SUCCESS',
    props<{ payload: MaintenanceHistoryCommentResponse[] }>(),
  );

  export const LoadMaintenanceCommentsError = createAction(
    '[Maintenance] LOAD_MAINTENANCE_COMMENTS_ERROR',
  );

  export const AddAndUpdateMaintenanceComments = createAction(
    '[Maintenance] ADD_AND_UPDATE_MAINTENANCE_COMMENTS',
    props<{ comment: string; history_id: number; comment_id?: number }>(),
  );
}
