import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  DashboardService,
  MaintenanceService,
} from '@connected/data-access/fleet-service';
import { Observable, switchMap, map, catchError, of, EMPTY } from 'rxjs';
import { MaintenanceActions } from './maintenance.actions';
import { SnackbarActions } from '@design-system/feature/snackbar';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceEffects {
  private _actions$ = inject(Actions);

  loadDashboardMaintenance$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(MaintenanceActions.LoadDashboardMaintenance),
      switchMap(({ payload }) =>
        this.dashboardService.getDashboardMaintenance(payload).pipe(
          map((data) =>
            MaintenanceActions.LoadDashboardMaintenanceSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            MaintenanceActions.LoadDashboardMaintenanceError(),
          ]),
        ),
      ),
    ),
  );

  LoadMaintenanceOverview$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(MaintenanceActions.LoadMaintenanceOverview),
      switchMap(() =>
        this._maintenanceService.getMaintenance().pipe(
          map((data) =>
            MaintenanceActions.LoadMaintenanceOverviewSuccess({
              payload: data,
            }),
          ),
          catchError(() => [MaintenanceActions.LoadMaintenanceOverviewError()]),
        ),
      ),
    ),
  );

  loadMaintenance$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(MaintenanceActions.LoadMaintenance),
      switchMap(({ payload }) =>
        this._maintenanceService
          .getMaintenanceByEquipmentNumber(payload, undefined, 'response')
          .pipe(
            map((resp) =>
              resp.status === 204
                ? MaintenanceActions.LoadMaintenanceErrorNoData()
                : resp.status === 200
                  ? MaintenanceActions.LoadMaintenanceSuccess({
                      maintenance: resp.body?.maintenance,
                    })
                  : MaintenanceActions.LoadMaintenanceError(),
            ),
            catchError(() => [MaintenanceActions.LoadMaintenanceError()]),
          ),
      ),
    ),
  );

  loadMaintenanceHistory$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(
        MaintenanceActions.LoadMaintenanceHistory,
        MaintenanceActions.RenewMaintenance,
      ),
      switchMap(({ payload }) =>
        this._maintenanceService
          .getMaintenanceHistoryByEquipmentNumber(payload)
          .pipe(
            map((data) =>
              MaintenanceActions.LoadMaintenanceHistorySuccess({
                maintenanceHistories: data.maintenance_histories,
              }),
            ),
            catchError(() => [
              MaintenanceActions.LoadMaintenanceHistoryError(),
            ]),
          ),
      ),
    ),
  );

  loadMaintenanceComments$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(MaintenanceActions.LoadMaintenanceComments),
      switchMap(({ payload }) =>
        this._maintenanceService
          .getMaintenanceHistoryCommentsByHistoryId(payload)
          .pipe(
            map((data) =>
              MaintenanceActions.LoadMaintenanceCommentsSuccess({
                payload: data,
              }),
            ),
            catchError(() => [
              SnackbarActions.ShowErrorSnackbar({
                messageKey: 'connected.maintenance.load_comments_error',
              }),
              MaintenanceActions.LoadMaintenanceCommentsError(),
            ]),
          ),
      ),
    ),
  );

  addAndUpdateMaintenanceComments$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(MaintenanceActions.AddAndUpdateMaintenanceComments),
      switchMap(({ comment, history_id, comment_id }) =>
        this._maintenanceService
          .updateMaintenanceHistoryComment(undefined, {
            comment,
            history_id,
            comment_id,
          })
          .pipe(
            switchMap(() => [
              SnackbarActions.ShowSuccessSnackbar({
                messageKey: 'connected.maintenance.comment_success',
              }),
              MaintenanceActions.LoadMaintenanceComments({
                payload: history_id,
              }),
            ]),
            catchError(() => [
              SnackbarActions.ShowErrorSnackbar({
                messageKey: 'connected.maintenance.save_comment_error',
              }),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private dashboardService: DashboardService,
    private _maintenanceService: MaintenanceService,
  ) {}
}
